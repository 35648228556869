import styled from "styled-components";
import { device, StyleFontHeader } from "../../Layout/theme";

interface StyledPastChampionsProps {
  numColumns: number;
}

const pastWinnerCardWhitespace = {
  left: "15px",
  right: "15px",
};

export const StyledPastChampions = styled.div<StyledPastChampionsProps>`
  /* Revisit if the Top Earners section returns to achieve consistent padding */
  /* padding-top: 40px; */

  .header {
    ${StyleFontHeader}
    font-size: 57px;
    line-height: 68px;
    letter-spacing: 0.06em;
  }

  .past-winner-year {
    ${StyleFontHeader};
    font-size: 32px;
    color: ${({ theme }) => theme.color_text_dimmed};
  }

  .past-winner-cards {
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin-bottom: 40px;
  }

  @media ${device.large} {
    margin: ${({ theme }) => theme.gutter_size_large};

    .past-winner-cards {
      .past-winner-card {
        /* Card width should be consistent across years */
        /* Split width evenly into columns, then adjust for whitespace */
        flex: 0 0 calc((100% / ${(props) => props.numColumns}) - (((${pastWinnerCardWhitespace.left} + ${pastWinnerCardWhitespace.right}) * (${(props) => props.numColumns} - 1)) / ${(props) => props.numColumns}));

        &:not(:last-child) {
          margin-right: ${pastWinnerCardWhitespace.right};
        }

        &:not(:first-child) {
          margin-left: ${pastWinnerCardWhitespace.left};
        }
      }
    }
  }

  @media ${device.small_and_medium} {
    .past-winner-cards {
      display: block;

      .past-winner-card {
        margin-bottom: 20px;
      }
    }
  }

  @media ${device.medium} {
    margin: ${({ theme }) => theme.gutter_size_medium};
  }

  @media ${device.small} {
    margin: ${({ theme }) => theme.gutter_size_small};
  }
`;
