import React, {Fragment, useEffect, useMemo, useState} from "react";
import {TFunction, useTranslation} from "react-i18next";
import {useFetch} from "../../../../hooks/use-fetch";
import {fetchStatsLeaderboard} from "../../../../services/championsQueueData";
import {
  StatsLeaderboardPlayer,
  StatsLeaderboardPlayerStatType
} from "../../../../services/championsQueueData/index.type";
import InformError from "../../../Inform/Error";
import InformLoading from "../../../Inform/Loading";
import ChampionsLeaderboardTable, {StatColumn} from "../Table";
import ChampionsStatsLeaderboardHeader from "./Header";
import {StyledChampionStatsLeaderboard} from "./style";
import {createFuse} from "../../../../helpers/utils";

const ChampionStatsLeaderboard: React.FC = () => {
  const { t } = useTranslation();

  const [currentQuery, setCurrentQuery] = useState<string>("");
  const [selectedStat, setSelectedStat] =
    useState<StatsLeaderboardPlayerStatType>("winPercent");

  const { isLoading, response, error } = useFetch(async () => {
    return await fetchStatsLeaderboard();
  }, []);
  const allPlayers = useMemo(() => response?.players ?? [], [response]);

  const [playersFiltered, setPlayersFiltered] =
    useState<StatsLeaderboardPlayer[]>([]);

  const playersFilteredWithRank = useMemo(
    () => playersFiltered.map((player) => ({
      ...player,
      rank: player.stats.find(
        (playerStat) => playerStat.name === selectedStat
      )!.rank
    })),
    [playersFiltered, selectedStat]
  );

  const fuse = useMemo(
    () => createFuse(allPlayers, (player) => player.name),
    [allPlayers]
  );

  const stats = useMemo(() => statsColumns(t, selectedStat),[t, selectedStat]);

  useEffect(() => {
    if (currentQuery.trim().length === 0) {
      setPlayersFiltered(allPlayers);
    }
    else {
      setPlayersFiltered(fuse.search(currentQuery).map((result) => result.item.player));
    }
  }, [selectedStat, currentQuery, allPlayers]);

  return (
    <StyledChampionStatsLeaderboard>
      {isLoading && <InformLoading />}
      {error && <InformError error={error} />}
      {response && (
        <Fragment>
          <ChampionsStatsLeaderboardHeader
            selectedStat={selectedStat}
            onSelectStat={setSelectedStat}
            onSearch={setCurrentQuery}
          />
          <ChampionsLeaderboardTable
            players={playersFilteredWithRank}
            stats={stats}
            highlightAndDividers={true}
          />
        </Fragment>
      )}
    </StyledChampionStatsLeaderboard>
  );
};

const statsColumns = (
  t: TFunction,
  stat: StatsLeaderboardPlayerStatType
): StatColumn<StatsLeaderboardPlayer>[] => {

  const getStatValue = (player: StatsLeaderboardPlayer): number =>
    player.stats.find(
      (playerStat) => playerStat.name === stat
    )!.value;

  const getRawStatValue = (player: StatsLeaderboardPlayer): number =>
    player.stats.find(
      (playerStat) => playerStat.name === stat
    )!.valueForRanking;

  return [
    {
      key: stat,
      className: "stats-leaderboard-value",
      label: t(`hallOfChampions:statsLeaderboard.headers.${stat}`),
      getValue: getStatValue,
      getRawValue: getRawStatValue,
      getValueFormattingOptions: {},
      renderValue: (player) => t(
        `hallOfChampions:statsLeaderboard.labels.${stat}`,
        { value: getStatValue(player).toLocaleString() }
      ),
      showDividers: true
    }
  ];
};

export default ChampionStatsLeaderboard;
