import styled from "styled-components";
import { device } from "../../../Layout/theme";

export const StyledChampionStatsLeaderboard = styled.div`
  @media ${device.large} {
    margin: 100px ${({ theme }) => theme.gutter_size_large};

    .stats-leaderboard-value {
      width: 300px;
    }
  }

  @media ${device.medium} {
    margin: 100px ${({ theme }) => theme.gutter_size_medium};

    .stats-leaderboard-value {
      width: 200px;
    }
  }

  @media ${device.small} {
    margin: 100px ${({ theme }) => theme.gutter_size_small};
  }
`;
