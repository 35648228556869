import styled from "styled-components";
import { StyleFontHeader } from "../../../Layout/theme";

export const StyledPastChampionCard = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .split-title {
    ${StyleFontHeader}
    font-size: 30px;
    padding: 5px 0;
    background-color: ${({ theme }) => theme.color_victory};
    color: ${({ theme }) => theme.color_text_dark};
    text-align: center;
    flex-grow: 0;
  }

  .winner-section {
    background-color: ${({ theme }) => theme.color_background_tertiary};
    padding: 20px;
    flex-grow: 1;

    .player-image {
      width: 100%;
      margin-bottom: 20px;
    }

    .player-name {
      ${StyleFontHeader}
      font-size: 32px;
      line-height: 32px;
    }

    .player-socials {
      display: flex;
      align-items: center;
      margin-top: 10px;
    }

    .player-bio {
      color: ${({ theme }) => theme.color_text_dimmed};
      margin-top: 10px;
    }
  }
`;
