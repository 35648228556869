import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useCurrencyFormatter } from "../../../hooks/use-currency-format";
import BackgroundImage from "gatsby-background-image-es5";
import { StyledTopEarnerChampions } from "./style";
import { convertToBgImage } from "gbimage-bridge";
import { useFetch } from "../../../hooks/use-fetch";
import { fetchTopEarners } from "../../../services/championsQueueData";
import InformLoading from "../../Inform/Loading";
import InformError from "../../Inform/Error";
import { TopEarner } from "../../../services/championsQueueData/index.type";
import placeholder from "../../../placeholders/topEarners.json";

const TopEarnerChampions: React.FC = () => {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormatter();

  const { isLoading, response, error } = useFetch(fetchTopEarners, []);

  const { patternImage } = useStaticQuery(
    graphql`
      query {
        patternImage: file(relativePath: { eq: "title-pattern.png" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
            )
          }
        }
      }
    `
  );
  const image = getImage(patternImage);
  const bgImage = convertToBgImage(image);

  const renderTopRow = (first: TopEarner) => (
    <div className="top-row">
      <BackgroundImage Tag="div" className="title-block" {...bgImage}>
        {t("hallOfChampions:topEarners")}
      </BackgroundImage>
      <div className="first-block">
        <div className="background-dollar-sign">
          {t("currencySymbol")}
        </div>
        <div className="amount">{formatCurrency(first.dollars)}</div>
        <div className="player-name">{first.name}</div>
      </div>
    </div>
  );

  const renderBottomRow = (rest: TopEarner[]) => (
    <div className="bottom-row">
      {rest.map((earner) => (
        <div key={earner.name} className="player-block">
          <div className="amount">{formatCurrency(earner.dollars)}</div>
          <div className="player-name">{earner.name}</div>
        </div>
      ))}
    </div>
  );

  const renderTopEarners = (topEarners: TopEarner[]) => (
    <Fragment>
      {renderTopRow(topEarners[0])}
      {renderBottomRow(topEarners.slice(1))}
    </Fragment>
  );

  return (
    <StyledTopEarnerChampions>
      {isLoading && <InformLoading />}
      {error && <InformError error={error} />}
      {!isLoading && (
        <Fragment>
          {response && response.topEarners.length > 0
            && renderTopEarners(response.topEarners)}
          {/* Consider bringing this back if we need to show placeholder data ex. EU */}
          {/*{(!response || response.topEarners.length === 0)*/}
          {/*  && renderTopEarners(placeholder.topEarners)}*/}
        </Fragment>
      )}
    </StyledTopEarnerChampions>
  );
};

export default TopEarnerChampions;
