import { withPrefix } from "gatsby-link";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  SeasonWinner,
  SeasonWinnerPlayer
} from "../../../../services/championsQueueData/index.type";
import { renderSocials } from "../../Social";
import { StyledPastChampionCard } from "./style";

export type Props = {
  winner: SeasonWinner
};

const PastChampionCard: React.FC<Props> = ({ winner }: Props) => {
  const { t } = useTranslation();

  const renderPlayer = (player: SeasonWinnerPlayer) => (
    <Fragment>
      {player.imageUrl && (
        <img
          className="player-image"
          src={
            player.imageUrl.startsWith("/")
              ? withPrefix(player.imageUrl)
              : player.imageUrl
          }
          // TODO what should the alt be? Does player name work?
          alt={player.name}
        />
      )}
      <div className="player-name">{player.name}</div>
      <div className="player-socials">
        {renderSocials(player.summonerName, player.socialLinks, false)}
      </div>
      <div className="player-bio">{player.bio}</div>
    </Fragment>
  );

  const renderComingSoon = () => (
    <Fragment>
      <div className="player-name">{t("hallOfChampions:comingSoon.title")}</div>
      <div className="player-bio">
        {t("hallOfChampions:comingSoon.description")}
      </div>
    </Fragment>
  );

  return (
    <StyledPastChampionCard>
      <div className="split-title">{winner.title}</div>
      <div className="winner-section">
        {winner.player && renderPlayer(winner.player)}
        {!winner.player && renderComingSoon()}
      </div>
    </StyledPastChampionCard>
  );
};

export default PastChampionCard;
