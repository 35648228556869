import styled from "styled-components";
import { StyleFontHeader } from "../../../../Layout/theme";

export const StyledChampionsStatsLeaderboardHeader = styled.div`
  margin-bottom: 30px;

  h2 {
    ${StyleFontHeader}
    font-size: 55px;
    letter-spacing: 0.05em;
  }

  .controls-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
