import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../hooks/use-fetch";
import { fetchPastWinners } from "../../../services/championsQueueData";
import { YearWinners } from "../../../services/championsQueueData/index.type";
// import InformError from "../../Inform/Error";
import InformLoading from "../../Inform/Loading";
import PastChampionCard from "./Card";
import { StyledPastChampions } from "./style";
import placeholder from "../../../placeholders/hallOfChampions.json";

const PastChampions: React.FC = () => {
  const { t } = useTranslation();

  const { isLoading, response /* , error */ } = useFetch(fetchPastWinners, []);

  const hallOfChampions =
    response && response.hallOfChampions.length > 0 ? response.hallOfChampions : placeholder.hallOfChampions;

  const maxChampionsInASeason = hallOfChampions.reduce((acc, year) => Math.max(year.seasons.length, acc), 0);

  const renderPastChampions = (winners: YearWinners[]) => (
    winners.map((season) => (
      <div key={season.year}>
        <div className="past-winner-year">{season.year}</div>
        <div className="past-winner-cards">
          {season.seasons.map((splitWinner, i) => (
            <div key={i} className="past-winner-card">
              <PastChampionCard winner={splitWinner} />
            </div>
          ))}
        </div>
      </div>
    ))
  );

  return (
    <StyledPastChampions numColumns={maxChampionsInASeason}>
      {isLoading && <InformLoading />}
      {/* TODO Uncomment once the lambda is able to create this file in all cases.
               As it stands, the file is not created when there are no matches meaning
               the endpoint 403s and there is an error. For now we just want to render
               placeholder data in this case and not worry about the error. */}
      {/* {error && <InformError error={error}/>} */}
      <div className="header">{t("nav.hallOfChampions")}</div>
      {!isLoading && (
        <Fragment>
          {renderPastChampions(hallOfChampions)}
        </Fragment>
      )}
    </StyledPastChampions>
  );
};

export default PastChampions;
