// Leaderboard (home) page

export type Leaderboards = {
  leaderboards: Leaderboard[]
};

export type Leaderboard = {
  seasonId: number
  title: string
  shortTitle: string
  year: number
  openDate: string
  closeDate: string
  // If defined, this is for a particular split, otherwise the whole season.
  split?: LeaderboardSplit
  lineup: LeaderboardPlayer[]
};

export type LeaderboardSplit = {
  splitId: number
  title: string // "March Split"
  openDate: string
  closeDate: string
};

export type LeaderboardPlayer = {
  summonerId: number
  name: string
  rank: number
  seasonPoints: number
  lp: number
  wins: number
  losses: number
  winRate: number
  socialLinks: SocialLink[]
};

export type SocialLink = {
  platform: SocialLinkPlatform
  live?: boolean
  link: string
};

export type SocialLinkPlatform =
  "twitch" | "youtube" | "instagram" | "twitter";

// About page

export type AboutConfig = {
  prizePools: PrizePoolConfig[]
  schedule: ScheduleConfig
  applicationUrl: string
  seasonPoints: SeasonPointsBreakdown[]
};

export type PrizePoolConfig = {
  title: string
  type: PrizePoolType
  prizes: PrizeConfig[]
};

export type PrizePoolType = "small" | "medium" | "large";

export type PrizeConfig = {
  rank: number
  dollars: number
};

export type ScheduleConfig = {
  year: string
  seasons: ScheduleSeasonConfig[]
};

export type ScheduleSeasonConfig = {
  title: string
  patchLower: string
  patchUpper?: string
  openDate: string
  closeDate: string
  closeDateForDisplay: string
  splits: SplitPrizeConfig[]
};

export type SplitPrizeConfig = {
  title: string,
  openDate: string
  closeDate: string
  closeDateForDisplay: string
};

export type SeasonPointsBreakdown = {
  minRank: number
  maxRank: number
  points: number
};

// Hall of Champions - top earners

export type TopEarners = {
  topEarners: TopEarner[]
};

export type TopEarner = {
  name: string
  dollars: number
};

// Hall of Champions - past winners

export type PastWinners = {
  hallOfChampions: YearWinners[]
};

export type YearWinners = {
  year: number
  seasons: SeasonWinner[]
};

export type SeasonWinner = {
  seasonId: number
  title: string
  // If player is not defined, it is "coming soon".
  player?: SeasonWinnerPlayer
};

export type SeasonWinnerPlayer = {
  name: string
  imageUrl?: string
  bio: string
  socialLinks: SocialLink[]
  summonerName: string
};

// Hall of Champions - stats leaderboard

export type StatsLeaderboard = {
  players: StatsLeaderboardPlayer[]
};

export type StatsLeaderboardPlayer = {
  name: string
  socialLinks: SocialLink[]
  stats: StatsLeaderboardPlayerStat[]
};

export const ALL_STAT_TYPES = ["winPercent", "kda", "killParticipation", "visionScorePerMinute", "goldPerMinute",
  "damageToChampionsPerMinute", "mostPlayedChampion"] as const;

export type StatsLeaderboardPlayerStatType = (typeof ALL_STAT_TYPES)[number];

export type StatsLeaderboardPlayerStat = {
  name: StatsLeaderboardPlayerStatType
  value: number
  valueForRanking: number
  rank: number
};

// Match History

export type MatchHistory = {
  matches: MatchHistoryMatch[]
};

export type MatchHistoryMatch = {
  matchStart: string
  gameVersion: string
  teams: [MatchHistoryMatchTeam, MatchHistoryMatchTeam]
};

export type MatchHistoryMatchTeam = {
  winner: boolean
  score: number
  players: MatchHistoryMatchTeamPlayer[]
};

export type MatchHistoryMatchTeamPlayer = {
  name: string
  summonerId: number
  championIcon: string
  spellIcon1: number
  spellIcon2: number
  kills: number
  deaths: number
  assists: number
  cs: number
  gold: number
  lane: PlayerLane
};

export type PlayerLane = "TOP" | "JUNGLE" | "MID" | "BOTTOM" | "SUPPORT";
