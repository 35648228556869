import { graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import ChampionStatsLeaderboard from "../components/Champions/Leaderboard/Stats";
import PastChampions from "../components/Champions/Past";
import TopEarnerChampions from "../components/Champions/TopEarners";
import HallOfChampionsExplainer from "../components/Explainer/HallOfChampions";
import HallOfChampionsFeature from "../components/Feature/HallOfChampions";
import Layout from "../components/Layout";
import { StyledLayoutContentArea } from "../components/Layout/style";

const HallOfChampionsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <GatsbySeo title={t("title")} />
      <HallOfChampionsFeature />
      <StyledLayoutContentArea>
        <HallOfChampionsExplainer />
        <TopEarnerChampions />
        <PastChampions />
        <ChampionStatsLeaderboard />
      </StyledLayoutContentArea>
    </Layout>
  );
};

export default HallOfChampionsPage;

export const query = graphql`
  query ($language: String!) {
    ...TranslationQueryFragment
  }
`;
