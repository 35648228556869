import styled from "styled-components";
import { device, StyleFontHeader } from "../../Layout/theme";

export const StyledTopEarnerChampions = styled.div`
  padding-top: 40px;

  .top-row {
    display: flex;
    align-items: stretch;
    height: 200px;

    .title-block {
      ${StyleFontHeader}
      width: 50%;
      font-size: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .first-block {
      width: 50%;
      background-color: ${({ theme }) => theme.color_victory};
      color: ${({ theme }) => theme.color_text_dark};
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-left: 70px;
      position: relative;
      overflow: hidden;

      .background-dollar-sign {
        position: absolute;
        font-size: 270px;
        font-weight: bold;
        color: ${({ theme }) => theme.color_victory_dark};
        border: 15px solid ${({ theme }) => theme.color_victory_dark};
        border-radius: 50%;
        width: 300px;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 0;
        top: 0;
        transform: translate(20%, -20%);
        pointer-events: none;
      }

      .amount {
        ${StyleFontHeader}
        font-size: 80px;
        line-height: 80px;
        font-weight: bold;
        z-index: 1;
      }

      .player-name {
        font-size: 28px;
        font-weight: bold;
        z-index: 1;
      }
    }
  }

  .bottom-row {
    display: flex;
    align-items: stretch;
    background-color: ${({ theme }) => theme.color_background_tertiary};

    .player-block {
      width: 25%;
      padding: 20px 20px 20px 70px;
      position: relative;

      .amount {
        ${StyleFontHeader}
        font-size: 46px;
      }

      .player-name {
        font-size: 22px;
        font-weight: bold;
      }
    }
  }

  @media ${device.large} {
    margin: ${({ theme }) => theme.gutter_size_large};

    .bottom-row {
      .player-block {
        &:not(:first-child) {
          &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 2px;
            top: 20%;
            bottom: 20%;
            background-color: ${({ theme }) => theme.color_rule};
          }
        }
      }
    }
  }

  @media ${device.small_and_medium} {
    .top-row {
      display: block;
      height: auto;

      .title-block {
        width: 100%;
        justify-content: flex-start;
        padding: 10px 20px;
      }

      .first-block {
        width: 100%;
        padding: 10px 20px;

        .background-dollar-sign {
          font-size: 190px;
          transform: translate(25%, -29%);
        }
      }
    }

    .bottom-row {
      flex-direction: column;

      .player-block {
        padding: 10px 20px;
      }
    }
  }

  @media ${device.medium} {
    margin: ${({ theme }) => theme.gutter_size_medium};
  }

  @media ${device.small} {
    margin: ${({ theme }) => theme.gutter_size_small};
  }
`;
