import styled from "styled-components";
import { device, StyleFontHeader } from "../../Layout/theme";

export const StyledHallOfChampionsFeature = styled.div`
  position: relative;
  min-height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color_background_tertiary};

  .title {
    ${StyleFontHeader};
    color: ${({ theme }) => theme.color_text};
    font-size: 140px;
    line-height: 140px;
    letter-spacing: 0.02em;

    .line1 {
      display: inline-block;
    }

    .line2 {
      display: inline-block;
      position: relative;
      margin-right: 100px;

      .logo {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(80%, -50%);
      }
    }
  }

  @media ${device.medium} {
    .title {
      text-align: center;
      font-size: 120px;
      line-height: 120px;

      .line1 {
        display: block;
      }
    }

    .logo {
      width: 90px;
      top: 50%;
      transform: translate(0%, -50%);
    }
  }

  @media ${device.small} {
    .title {
      font-size: 75px;
      line-height: 75px;
      text-align: center;

      .line1 {
        display: block;
      }

      .line2 {
        margin-right: 0;
      }
    }

    .logo {
      width: 60px;
      top: 50%;
      right: 100px;
      transform: translate(0%, -50%);
    }
  }
`;
