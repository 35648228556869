import React from "react";
import { useTranslation } from "react-i18next";
import { StyledHallOfChampionsExplainer } from "./style";

const HallOfChampionsExplainer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledHallOfChampionsExplainer>
      <p className="message">{t("hallOfChampions:explainer")}</p>
    </StyledHallOfChampionsExplainer>
  );
};

export default HallOfChampionsExplainer;
