import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import {
  ALL_STAT_TYPES,
  StatsLeaderboardPlayerStatType
} from "../../../../../services/championsQueueData/index.type";
import Dropdown, { DropdownOption } from "../../../../Ui/Dropdown";
import ChampionsSearch from "../../../Search";
import { StyledChampionsStatsLeaderboardHeader } from "./style";

export type Props = {
  selectedStat: StatsLeaderboardPlayerStatType
  onSelectStat: (stat: StatsLeaderboardPlayerStatType) => void
  onSearch: (query: string) => void
};

const ChampionsStatsLeaderboardHeader: React.FC<Props> = ({
  selectedStat,
  onSelectStat,
  onSearch
}: Props) => {
  const { t } = useTranslation();

  const options: DropdownOption<StatsLeaderboardPlayerStatType>[] =
    ALL_STAT_TYPES.map((stat) => ({
      value: stat,
      label: t(`hallOfChampions:statsLeaderboard.headers.${stat}`)
    }));

  return (
    <StyledChampionsStatsLeaderboardHeader>
      <h2>{t("hallOfChampions:statsLeaderboard.title")}</h2>
      <div className="controls-row">
        <Dropdown
          label={t("hallOfChampions:statsLeaderboard.selectStat")}
          options={options}
          selected={selectedStat}
          getKey={(stat) => stat}
          onChange={onSelectStat}
        />
        <ChampionsSearch onSearch={onSearch} />
      </div>
    </StyledChampionsStatsLeaderboardHeader>
  );
};

export default ChampionsStatsLeaderboardHeader;
